.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.loading-text {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	width: 100%;
	height: 100px;
	line-height: 100px;
}

.loading-text span {
  display: inline-block;
  margin: 0 5px;
  color: inherit;
}

.loading-text span:nth-child(1) {
  filter: blur(0px);
  animation: blur-text 1s 1s infinite linear alternate;
}

.loading-text span:nth-child(2) {
  filter: blur(0px);
  animation: blur-text 1s 1.1s infinite linear alternate;
}

.loading-text span:nth-child(3) {
  filter: blur(0px);
  animation: blur-text 1s 1.2s infinite linear alternate;
}

.loading-text span:nth-child(4) {
  filter: blur(0px);
  animation: blur-text 1s 1.3s infinite linear alternate;
}

.loading-text span:nth-child(5) {
  filter: blur(0px);
  animation: blur-text 1s 1.4s infinite linear alternate;
}

.loading-text span:nth-child(6) {
  filter: blur(0px);
  animation: blur-text 1s 1.5s infinite linear alternate;
}

.loading-text span:nth-child(7) {
  filter: blur(0px);
  animation: blur-text 1s 1.6s infinite linear alternate;
}

.loading-text span:nth-child(8) {
  filter: blur(0px);
  animation: blur-text 1s 1.7s infinite linear alternate;
}

.loading-text span:nth-child(9) {
  filter: blur(0px);
  animation: blur-text 1s 1.8s infinite linear alternate;
}

@keyframes blur-text {
	0% {filter: blur(0px);}
	100% {filter: blur(4px);}
}
