@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700|Quattrocento+Sans:400,700|Oxygen+Mono');

/* width */
::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(192,192,192,.5);
  border-radius: 25rem;
  transition: background-color .3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(192,192,192,.77);
}

body,
.font-family-body {
  font-family: 'Oxygen', sans-serif;
}

code {
  color: inherit;
}

.form-control[type="email"],
.font-family-monospace {
  font-family: 'Oxygen Mono', monospace;
}

.cursor-pointer {
  cursor: pointer;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.font-family-header {
  font-family: 'Quattrocento Sans', sans-serif;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #a0a7af;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #a0a7af;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #a0a7af;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  color: #a0a7af;
}

input:focus, input:active,
select:focus, select:active,
textarea:focus, textarea:active,
button:focus, button:active,
.form-control:focus, .form-control:active {
  outline: none;
  box-shadow: none;
  border-color: #ffbc00;
}

details summary {
  outline: none;
}

a:hover {
  text-decoration: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.overflow-auto,
.overflow-sm-auto,
.overflow-md-auto,
.overflow-lg-auto {
  -webkit-overflow-scrolling: touch;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.transition-all-0333s {
  transition: all .333s ease-out;
}

.overflow-auto {
  overflow: auto;
}

.line-height-dec {
  line-height: 1.3;
}

.line-height-1 {
  line-height: 1;
}

.font-size-dec {
  font-size: 0.9rem;
}

.font-size-base {
  font-size: 1rem;
}

.bg-filter-blur {
  backdrop-filter: blur(.15rem);
}

.bg-white-50 {
  background-color: rgba(255,255,255,0.5);
}

.bg-white-59 {
  background-color: rgba(255,255,255,0.59);
}

.bg-hover-white-70:hover {
  background-color: rgba(255,255,255,0.7);
}

.bg-hover-white:hover {
  background-color: #fff;
}

.bg-black-333 {
  background-color: rgba(0,0,0,0.333);
}

.bg-black-50 {
  background-color: rgba(0,0,0,0.5);
}

.bg-black-70 {
  background-color: rgba(0,0,0,0.7);
}

.text-theme {
  color: #ffbc00;
}

.bg-theme {
  background-color: #ffbc00;
}

.border-theme {
  border-color: #ffbc00 !important;
}

.btn-theme {
  background-color: #ffbc00;
  color: #000;
}

.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active {
  background-color: #ffcc10;
  color: #000;
}

.btn-outline-theme {
  border-color: #ffbc00;
}

.btn-outline-theme:not([class*="text-"]) {
  color: #ffbc00;
}

.btn-outline-theme:not([class*="bg-"]) {
  background-color: rgba(0,0,0,0.7);
}

.btn-outline-theme:hover,
.btn-outline-theme:focus,
.btn-outline-theme:active {
  color: #000;
  background-color: #ffcc10;
}

.bkg-img {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.bkg-img.bkg-img-top {
  background-position: 50% 0;
}

.perspective-1 {
  perspective: 1000px;
}

.text-white-07 {
  color: rgba(255,255,255,.7);
}

.text-color-inherit {
  color: inherit;
}

.text-color-hover-inherit:hover {
  color: inherit;
}

.text-hover-white:hover {
  color: #fff;
}

.text-active-white:active,
.text-active-white.active {
  color: #fff;
}

.text-glow-black {
  text-shadow: 0 0 2rem rgba(0,0,0,.7);
}

.letter-spacing-inc {
  letter-spacing: 0.1rem;
}

.metro-1 {
  font-size: 28px;
}

.metro-3 {
  font-size: 24px;
}

.metro-5 {
  font-size: 18px;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.vh-100 {
  height: 100vh;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-pill {
  border-radius: 64px;
}

.flex-basis-auto {
  flex-basis: auto;
}

.separator {
  width: 80px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
}

.separator:not([class*="bg-"]) {
  background-color: #ffbc00;
}

.fill { 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.min-100vh {
  min-height: 100vh !important;
}

.minheight-1 {
  min-height: 0.25rem;
}

.minheight-2 {
  min-height: 0.5rem;
}

.minheight-3 {
  min-height: 1rem;
}

.minheight-4 {
  min-height: 1.5rem;
}

.minheight-5 {
  min-height: 3rem;
}

.mw-card {
  max-width: 280px;
} 

.mw-tab {
  max-width: 320px;
}

.mw-logo {
  max-width: 200px;
}

.mw-xl {
  max-width: 1140px;
}

.height-gmap {
  height: 59vh;
  min-height: 380px;
}

.minheight-content {
  min-height: 240px;
}

.shape-padding:before {
  content: "";
  display: block;
  position: relative;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
}

.padding-square:before {
  padding-top: 100%;
}

.padding-portrait:before {
  padding-top: 133.333%;
}

.perspective-1000 {
  perspective: 1000px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.group-hover {
  transition: transform 1s ease-out;
  perspective: 1000px;
  transform: translateZ(0);
}

.group-hover:hover {
  transform: translateZ(-1.5rem);
}

.group-hover .group-hover-item {
  transition: transform 1s ease-out, background-color 1s ease-out, z-index 1s ease-out, opacity 1s ease-out, box-shadow 1s ease-out;
}

.group-hover:hover .group-hover-item {
  z-index: 0;
  opacity: .59;
  /*transform: perspective(1000px) translateZ(-2rem);*/
  filter: blur(1px);
  box-shadow: none;
}

.group-hover:hover .group-hover-item:hover {
  z-index: 1;
  opacity: 1;
  transform: perspective(1000px) translateZ(4.5rem);
  filter: blur(0);
  box-shadow: 4px 12px 24px 4px rgba(0,0,0,0.25);
}

.blur-01 {
  filter: blur(.25rem);
}

.blur-02 {
  filter: blur(.5rem);
}

.blur-03 {
  filter: blur(.75rem);
}

.scale-01 {
  transform: scale(1.025);
}

.scale-02 {
  transform: scale(1.05);
}

.scale-03 {
  transform: scale(1.075);
}

.transition-all-1s {
  transition: all 1s;
}

.x-0 {
  left: 0;
}

.x-100 {
  right: 0;
}

.y-0 {
  top: 0;
}

.y-100 {
  bottom: 0;
}

@media (min-width:576px) {

  .metro-1 {
    font-size: 40px;
  }
  
  .metro-3 {
    font-size: 33px;
  }

  .metro-5 {
    font-size: 24px;
  }

  .mw-sm-none {
    max-width: none;
  }
}

@media (min-width:768px) {

  .metro-1 {
    font-size: 44px;
  }
  
  .metro-3 {
    font-size: 36.5px;
  }

  .metro-5 {
    font-size: 25px;
  }
    
  .mw-md-66vw {
    max-width: 66vw;
  }

  .mw-md-75vw {
    max-width: 75vw;
  }
  
  .mw-md-none {
    max-width: none;
  }

}

@media (min-width:992px) {

  .metro-1 {
    font-size: 55px;
  }
  
  .metro-3 {
    font-size: 45px;
  }

  .metro-5 {
    font-size: 28px;
  }

  .mw-lg-66vw {
    max-width: 66vw;
  }

  .mw-lg-none {
    max-width: none;
  }

  .padding-lg-square:before {
    padding-top: 100%;
  }
}

@media (min-width:1200px) {

  .overflow-xl-visible {
    overflow: visible;
  }

  .position-xl-absolute {
    position: absolute;
  }
  
  .mw-xl-none {
    max-width: none;
  }

  .minheight-xl {
    min-height: 85px;
  }
  
}