@import url('https://fonts.googleapis.com/css?family=Monda:400,700');

.header-bg {
  background-color: rgba(0,0,0,0.333);
}

.header-bg-responsive {
  background-color: #000;
}

.header-position-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: auto;
  z-index: 1000;
}

.header-position-responsive {
  position: relative;
}

.header-border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #ffbc00;
}

.site-name {
  font-family: 'Monda', sans-serif;
}

.nav-wrapper:not(.open) .nav-btn .label-btn.dismiss {
  display: none;
}

.nav-wrapper.open .nav-btn .label-btn.expand {
  display: none;
}

.nav-wrapper:not(.open) .nav-ul {
  display: none;
}

.minheight-nav {
  min-height: 3rem;
}

@media (min-width:768px) {
  .nav-wrapper:not(.open) .nav-ul {
    display: block;
  }
}

@media (min-width:1200px) {

  .header-bg-responsive {
    background-color: rgba(0,0,0,0.7);;
  }
  
  .header-position-responsive {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;
    z-index: 1000;
  }

}